import './App.css';
import logos from './assets/LOGO.png';
import bg from './assets/Splash Screen (4) 1.png';
import mblbg from "./assets/mblscreen.png"

function App() {
  return (
    <>
      <div className='w-full md:pt-16 md:px-14 px-4 pt-7'>
        <img src={logos} alt="" />
      </div>
      <div className='w-full px-0 md:px-6 lg:px-0 justify-center flex flex-col-reverse md:flex-row'>
        {/* On mobile, flex column-reverse; On larger screens (md and above), flex row */}
        <div className='w-full  lg:w-[60%] md:justify-start justify-center flex md:pt-28'>
          <div className='w-full px-0 lg:px-16 md:px-12 '>
            <h1 className='text-3xl md:text-6xl font-bold flex justify-start sm:justify-center md:justify-start  pt-8  text-[#96105a]'>Coming Soon</h1>
            <h1 className='md:text-2xl text-sm items-center text-center md:text-justify px-14 sm:px-0 pt-4 md:pt-12 text-gray-500 w-[70%]'>InforReel will launch soon. Join the waitlist to try
              the beta before it's publicly available.<br/>
              Email address.</h1>
            <div className='pt-12 px-4 sm:px-0 w-full flex gap-x-8'>
              <input type="search" placeholder='Enter your email' className='border w-full md:w-[60%] px-4 py-4 rounded-full' />
              <button className='bg-[#96105a] px-10 rounded-full text-white hidden sm:block'>Join waitlist</button>
              <button className='bg-[#96105a] px-10 rounded-full text-white sm:hidden'>Join </button>
            </div>
          </div>
        </div>
        <div className='w-full hidden md:block md:w-[40%]'>
          <img src={bg} alt="" className=' md:h-full' />
        </div>
        <div className='w-full md:hidden items-center flex justify-center pt-10 md:w-[40%]'>
          <img src={mblbg} alt="" className='md:h-full' />
        </div>
      </div>
    </>
  );
}

export default App;
